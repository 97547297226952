import { VFC } from 'react'
import Touchable from '@sport1/news-styleguide/Touchable'
import Font from '@sport1/news-styleguide/Font'
import { spacings } from '@sport1/news-styleguide/spacings'
import { sport1Regular } from '@/helpers/fonts'

type ActionBarProps = {
    testID?: string
}
export const ActionBar: VFC<ActionBarProps> = ({ testID }) => (
    <>
        <Touchable
            href="/news/unternehmen/service/adblocker-deaktivieren"
            paddingTop={spacings['spacing-9']}
            testID={testID}
        >
            <Font
                fontVariant="Body-L"
                fontFamilyVariant="Sport1-Regular"
                nextFontFamilyVariant={sport1Regular.style.fontFamily}
            >
                1. Stelle deinen Adblocker für sport1.de ab
            </Font>
        </Touchable>
        <Touchable onPress={() => window.location.reload()} paddingTop={spacings['spacing-6']}>
            <Font
                fontVariant="Body-L"
                fontFamilyVariant="Sport1-Regular"
                nextFontFamilyVariant={sport1Regular.style.fontFamily}
            >
                2. sport1.de neu laden
            </Font>
        </Touchable>
    </>
)

export default ActionBar
