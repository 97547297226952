import { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Touchable from '@sport1/news-styleguide/Touchable'
import Font from '@sport1/news-styleguide/Font'
import { sport1Regular } from '@/helpers/fonts'

type NavigationBarProps = {
    testID?: string
}
export const NavigationBar: VFC<NavigationBarProps> = ({ testID }) => (
    <NonFlexingContainer horizontal paddingTop="spacing-9" testID={testID}>
        <Touchable href="/unternehmen/impressum">
            <Font
                fontVariant="Body-L"
                fontFamilyVariant="Sport1-Regular"
                color="GRAY50"
                nextFontFamilyVariant={sport1Regular.style.fontFamily}
            >
                Impressum
            </Font>
        </Touchable>
        <Font
            fontVariant="Body-L"
            fontFamilyVariant="Sport1-Regular"
            nextFontFamilyVariant={sport1Regular.style.fontFamily}
            color="GRAY50"
            paddingLeft="spacing-2"
            paddingRight="spacing-2"
        >
            |
        </Font>
        <Touchable href="/unternehmen/datenschutz">
            <Font
                fontVariant="Body-L"
                fontFamilyVariant="Sport1-Regular"
                color="GRAY50"
                nextFontFamilyVariant={sport1Regular.style.fontFamily}
            >
                Datenschutz
            </Font>
        </Touchable>
    </NonFlexingContainer>
)

export default NavigationBar
