import React, { VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Image from '@sport1/news-styleguide/Image'
import Font from '@sport1/news-styleguide/Font'
import { PixelType } from '@sport1/news-styleguide/Types'
import NavigationBar from '@/components/AdBlockerHint/HardBlock/NavigationBar'
import ActionBar from '@/components/AdBlockerHint/HardBlock/ActionBar'
import { PAGE_REFERRING_WIDGET_ID } from '@/utils/tracking/TrackingProvider'
import { sport1CondensedBlackItalic, sport1Regular } from '@/helpers/fonts'

const HINT_WIDTH = 375
const HINT_HEIGHT = 545
export const AD_BLOCKER_EVENT_TYPE = 'HARD_BLOCK'

const HardBlockHint: VFC = () => {
    React.useEffect(() => {
        window.dispatchEvent(new Event(AD_BLOCKER_EVENT_TYPE))
        localStorage.setItem(PAGE_REFERRING_WIDGET_ID, 'full_blocker_banner')
    })
    return (
        <NonFlexingContainer
            width={HINT_WIDTH}
            position="fixed"
            top={`calc(50% - ${HINT_HEIGHT / 2}px)` as PixelType}
            left={`calc(50% - ${HINT_WIDTH / 2}px)` as PixelType}
            zIndex={2147483647}
            borderRadius="radius-m"
            elevation={4}
            backgroundColor="WHITE"
        >
            <Image
                size="L"
                height={211}
                alt="Referee shows a red card"
                radiusType="top_rounded"
                imageUrl="/images/adBlocker/red-card.png"
            />
            <NonFlexingContainer paddingY="spacing-9" paddingX="spacing-9">
                <Font
                    fontVariant="Headline-S"
                    fontFamilyVariant="Sport1-CondensedBlackItalic"
                    nextFontFamilyVariant={sport1CondensedBlackItalic.style.fontFamily}
                >
                    Vielen Dank für deinen Besuch!
                </Font>
                <Font
                    fontVariant="Body-L"
                    fontFamilyVariant="Sport1-Regular"
                    nextFontFamilyVariant={sport1Regular.style.fontFamily}
                    paddingTop="spacing-7"
                >
                    Unser Angebot wird mit Werbung finanziert. Bitte hab Verständnis, dass unsere
                    Inhalte bei aktivem Adblocker nicht frei zugänglich sind. Damit du unsere
                    Inhalte weiterhin ansehen kannst, schalte bitte deinen Adblocker für sport1.de
                    aus.
                </Font>
                <ActionBar testID="ad-blocker-hard-action-bar" />
                <NavigationBar testID="ad-blocker-hard-navigation-bar" />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default HardBlockHint
